import axios from "../../utils/axios";
const accident = "/accident";
export default {
  getShelterTypes(params) {
    return axios.get(accident + `/dict/types`, { params });
  },
  // 获取危化品列表
  getSdsList(params) {
    return axios.get("/basics/list/sds", { params: params });
  },
  // 事故上报
  addAccident(data) {
    return axios.post("/accident/add/accident", data);
  },
  // 查询事故记录详情
  infoAccident(id) {
    return axios.get(`/accident/info/accident/${id}`);
  },
  // 生成事故编号
  accidentGenerateCode(params) {
    return axios.get(`/accident/generate/code`, { params: params });
  }
};
