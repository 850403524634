<template>
  <div class="accidentReport">
    <van-nav-bar
      title="事故上报"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="formBody">
      <van-form ref="form" label-width="30%">
        <!-- <van-cell-group>
          <van-field
            v-model="accidentForm.name"
            label="事故名称"
            rows="1"
            autosize
            type="textarea"
            placeholder="自动获取最新事故名称"
            readonly
          />
          <van-field
            v-model="accidentForm.code"
            type="text"
            label="事故编码"
            readonly
            placeholder="自动获取对应事故编码"
          />
        </van-cell-group> -->

        <van-cell-group>
          <!--          <van-datetime-picker-->
          <!--            v-model="accidentForm.startTime"-->
          <!--            type="datetime"-->
          <!--            title="请选择事发时间"-->
          <!--            :min-date="minDate"-->
          <!--            :max-date="maxDate"-->
          <!--          />-->
          <selectTime
            :value="accidentForm.startTime"
            label="*事发时间"
            name="事发时间"
            placeholder="请选择事发时间"
            :rules="[{ required: true }]"
          ></selectTime>

          <van-field
            v-model="accidentForm.place"
            type="text"
            label="*事发地点"
            name="事发地点"
            right-icon="location"
            placeholder="打开地图选点"
            :rules="[{ required: true }]"
            :class="{ geoNoSelect: geoVaild }"
            @click-right-icon="openMap"
          />
          <van-field
            v-model="accidentForm.accidentTypeName"
            label="*事故类型"
            name="事故类型"
            readonly
            is-link
            placeholder="请选择事故类型"
            :rules="[{ required: true }]"
            @click="popupGradeVisiable = true"
          />
          <van-field name="switch" label="*是否应急演练" input-align="right">
            <template #input>
              <van-switch
                v-model="accidentForm.isDrill"
                active-value="1"
                inactive-value="0"
                size="36px"
              />
            </template>
          </van-field>
          <van-field
            v-model="accidentForm.dangerousName"
            label="事故危险物质"
            name="事故危险物质"
            is-link
            rows="1"
            readonly
            autosize
            type="textarea"
            placeholder="请选择事故危险物质（多选）"
            @click="popupGradeVisiable2 = true"
          />
          <van-field
            v-model="accidentForm.deathNum"
            type="number"
            label="*人员伤亡"
            name="人员伤亡"
            placeholder="请输入人员伤亡数字"
            :rules="[{ required: true }]"
            @change="accidentForm.level = accidentForm.deathNum ? 2 : 3"
          />
        </van-cell-group>
        <div class="blocktitle">事故描述</div>
        <van-cell-group>
          <van-field
            v-model="accidentForm.description"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请输入事故描述"
            show-word-limit
          />
        </van-cell-group>
        <div class="blocktitle">备注</div>
        <van-cell-group>
          <van-field
            v-model="accidentForm.remark"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请输入备注信息"
            show-word-limit
          />
        </van-cell-group>
        <div class="blocktitle">附件（支持上传图片、视频、录音）</div>
        <div class="filecon">
          <FileUploader
            v-model="accidentForm.fileList"
            :max-count="1"
            :unable-upload="false"
            :accept="FILE_ACCEPT_TYPES.FILE"
            @change="fileUploadEmit"
          />
        </div>
        <div style="margin: 16px;padding: 0 16px;margin-top:36px;">
          <div
            :class="['btn look', canClick ? '' : 'noClick']"
            @click="onSubmit('preview')"
          >
            预览
          </div>
          <div
            :class="['btn submit', canClick ? '' : 'noClick']"
            @click="onSubmit('save')"
          >
            保存
          </div>
        </div>
      </van-form>
    </div>
    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="accidentForm.accidentType"
      :multi="false"
      :immediately="false"
      title="事故类型"
      :list="accidentTypeList"
      :visible="popupGradeVisiable"
      list-key="value"
      @change="popupGradeChange"
      @close="popupGradeVisiable = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="accidentForm.dangerous"
      :multi="true"
      :immediately="false"
      title="事故危险物质"
      :list="dangerousList"
      :visible="popupGradeVisiable2"
      :props="{ label: 'sdsName', value: 'id' }"
      list-key="id"
      @change="popupGradeChange2"
      @close="popupGradeVisiable2 = false"
    />
  </div>
</template>

<script>
import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { formatDates } from "./utils/formDate";
import api from "./api";
import selectTime from "./components/selectTime";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  name: "AccidentReport",
  data() {
    return {
      accidentForm: {
        id: "",
        name: "",
        code: "",
        source: 0, // 事故来源 暂时默认为0  0：人工接报 ，1：环保平台 2：传感器检测
        status: "0", // 状态 0：保存，1：未处置，2：救援中，3：已处置
        level: "", // 事故等级
        startTime: "", // 事发时间
        place: "", // 事发地点
        accidentType: "", // 事故类型
        accidentTypeName: "", // 事故类型 --- 展示
        isDrill: 0, // 是否应急演练
        dangerous: "", // 事故危险物质
        dangerousName: "", // 事故危险物质 --- 展示
        description: "", // 事故描述
        deathNum: "", // 人员伤亡
        callPerson: "", // 报警人
        callPhone: "", // 报警人联系方式
        subDept: "", // 报送部门
        subPerson: "", // 报送人员
        subPersonName: "", // 报送人员名字
        subTime: "", // 报送时间
        geo: null, // 事故地点
        fileList: [],
        attachmentId: "", // 附件id
        attachmentName: "", // 附件名称
        remark: "" // 备注
      },
      geoVaild: false, // 判断geo点位是否已选择
      accidentTypeList: [], // 事故类型-字典值
      dangerousList: [], // 事故危险物质 - 字典值
      levelDictList: [
        { label: "一级", value: 1 },
        { label: "二级", value: 2 },
        { label: "三级", value: 3 },
        { label: "四级", value: 4 }
      ],
      popupGradeVisiable: false, // 事故类型弹框
      popupGradeVisiable2: false, // 事故危险物质弹框
      name: "",
      code: "",
      fileList: [],
      FILE_ACCEPT_TYPES
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      geo: state => state.accidentReportStore.geo,
      saveAccidentReportId: state =>
        state.accidentReportStore.saveAccidentReportId
    }),
    canClick() {
      console.log(this.geo);
      return (
        this.accidentForm.startTime != "" &&
        this.accidentForm.place != "" &&
        this.geo &&
        this.accidentForm.accidentTypeName != "" &&
        this.accidentForm.deathNum != ""
      );
    }
  },
  components: {
    FileUploader,
    selectTime,
    SelectPopupGrade
  },
  async created() {
    await this.getDictList();
    await this.getSdsList();
    this.$nextTick(async () => {
      this.accidentForm.startTime = formatDates(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      );
      await this.getGenerateCode();
    });
    this.geoVaild = false;
    if (this.saveAccidentReportId) {
      this.getInfo(this.saveAccidentReportId);
    }
  },
  activated() {
    if (this.geo) {
      this.geoVaild = false;
    }
  },
  methods: {
    // 获取保存的数据
    getInfo(id) {
      api.infoAccident(id).then(data => {
        for (let key in this.accidentForm) {
          if (data[key] !== null) {
            this.accidentForm[key] = data[key];
          }
        }
        if (this.accidentForm.attachmentId) {
          this.accidentForm.fileList = [
            {
              id: this.accidentForm.attachmentId,
              name: this.accidentForm.attachmentName
            }
          ];
        }
        this.$store.commit("setGeo", this.accidentForm.geo);
        if (this.accidentForm.dangerous) {
          let dangerousList = [];
          this.accidentForm.dangerous.split(",").map(item => {
            dangerousList.push(
              this.handleDictData(
                item,
                this.dangerousList,
                "--",
                "id",
                "sdsName"
              )
            );
          });
          this.accidentForm.dangerousName = dangerousList.join(",");
        }
      });
    },
    // 获取字典值
    getDictList() {
      api.getShelterTypes({ types: "planAccidentType" }).then(data => {
        this.accidentTypeList = data.planAccidentType || [];
      });
    },
    // 获取危化品列表
    async getSdsList() {
      this.isLoading = true;
      this.dangerousList = [];
      await api
        .getSdsList()
        .then(data => {
          this.dangerousList = data || [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 生成事故编码
    async getGenerateCode() {
      if (this.accidentForm.code) return;
      await api
        .accidentGenerateCode({ startTime: this.accidentForm.startTime })
        .then(data => {
          this.accidentForm.code = data || "";
        });
    },
    // 选择事故类型返回值
    popupGradeChange(val) {
      this.accidentForm.accidentType = "";
      this.accidentForm.accidentTypeName = "";
      if (val && val.length > 0) {
        this.accidentForm.accidentType = val[0].value;
        this.accidentForm.accidentTypeName = val[0].label;
        this.accidentForm.name = `${formatDates(
          new Date(this.accidentForm.startTime),
          "yyyy年MM月dd日hh时mm分ss秒"
        )}在${this.accidentForm.place}发生${
          this.accidentForm.accidentTypeName
        }`;
      }
    },
    // 选择事故危险物质返回值
    popupGradeChange2(val) {
      this.accidentForm.dangerous = "";
      this.accidentForm.dangerousName = "";
      if (val && val.length > 0) {
        this.accidentForm.dangerous = val.map(item => item.id).join(",");
        this.accidentForm.dangerousName = val
          .map(item => item.sdsName)
          .join(",");
      }
    },
    // 上传附件返回值
    fileUploadEmit(val) {
      this.accidentForm.attachmentId = val ? val.id : "";
      this.accidentForm.attachmentName = val ? val.name : "";
    },
    onClickLeft() {
      history.go(-1);
    },
    onSubmit(type) {
      if (!this.canClick) {
        return;
      }
      this.$refs.form
        .validate()
        .then(async data => {
          this.accidentForm.geo = this.geo;
          this.accidentForm.orgCode = this.userInfo.orgCode;
          if (
            !this.accidentForm.geo ||
            JSON.stringify(this.accidentForm.geo) === "{}"
          ) {
            this.geoVaild = true;
            Toast("请选择事故发生点");
            return;
          }
          // this.$store.commit("setGeo", null);
          if (type === "preview") {
            let data = encodeURIComponent(JSON.stringify(this.accidentForm));
            this.$router.push(`/preview?data=${data}`);
          } else if (type === "save") {
            await api.addAccident(this.accidentForm).then(data => {
              this.$store.commit("setSaveAccidentReportId", data);
              history.go(-1);
              Toast("保存成功");
            });
          }
        })
        .catch(err => {
          console.log(err);
          if (err && err.length > 0) {
            Toast(err[0].name + "不能为空");
          }
        });
    },
    onFailed() {
      console.log("onFailed");
    },
    preview() {
      this.$router.push(`/preview`);
    },
    openMap() {
      this.$router.push(`/map`);
    },
    // 处理字典数据
    handleDictData(
      value,
      dataList = [],
      placeholder = "",
      valueKey = "value",
      labelKey = "label"
    ) {
      let optionName = placeholder;
      dataList.map(val => {
        if (value === val[valueKey]) {
          optionName = val[labelKey];
        }
      });
      return optionName;
    }
  }
};
</script>

<style lang="scss" scoped>
.accidentReport {
  div {
    box-sizing: border-box;
  }
  font-family: PingFangSC-Regular, PingFang SC;
  .formBody {
    padding-top: 16px;
    padding-bottom: 100px;
    background: #f7f8fa;
    overflow: auto;
    height: calc(100vh - 60px);
    .blocktitle {
      padding-left: 16px;
      font-size: 14px;
      color: #646566;
      margin-bottom: 8px;
    }
    .filecon {
      padding: 14px;
      padding-left: 16px;
      background: #fff;
    }
    .btn {
      border-radius: 22px;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      text-align: center;
    }
    .look {
      background: linear-gradient(180deg, #1677ff 0%, #62a6f8 100%);
      color: #ffffff;
      margin-bottom: 18px;
    }
    .submit {
      border: 1px solid #3e8dfe;
      background: #ffffff;
      color: #3e8dfe;
    }
    .noClick {
      opacity: 0.7;
    }
  }
  .van-cell-group {
    margin-bottom: 18px;
  }
  ::v-deep .geoNoSelect {
    .van-field__value {
      .van-field__right-icon {
        color: #e03631;
      }
    }
  }
}
</style>
